/** document.getElementById */
export const GE = (elementID) => document.getElementById(elementID);

/**
 * document.querySelector
 * @returns {HTMLElement}
 */
export const QS = (query) => document.querySelector(query);

/**
 * document.querySelectorAll
 * @returns {HTMLElement[]}
 */
export const QSAll = (query) => document.querySelectorAll(query);
